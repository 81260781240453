.song-list-header {
    text-decoration: underline;
}

.song-list {
    padding: 1%;
    margin: 1% 15%;
    background-color: black;
}
.item {
    margin: 3% 0%;
}

@media only screen and (max-width: 992px) {
    .item {
        margin: 6% 0%;
    }
}
