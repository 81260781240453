header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2%;
}

h2 {
    margin: 10% 0%;
}

img {
    /* margin-right: 10%; */
    height: 50vh;
    width: 50vw;
}

.show-child {
    align-self: center;
    margin: 3%;
    flex-basis: 15%;
}

.widget_iframe {
    width: 70%;
    margin: 1.5% 0%;
    margin-bottom: 20vh;
}

@media only screen and (max-width: 992px) {
    h2 {
        font-size: large;
    }

    img.show-child {
        width: 60%;
        height: 60%;
    }

    .widget_iframe {
        margin-top: 5%;
        width: 80%;
    }
}