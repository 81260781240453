img {
    width: 35%;
    height: auto;
    float: left;
    margin-right: 4%;
}

p {
    font-size: larger;
}

h2 {
    margin-top: 3%;
    /* margin-left: 5%; */
}

.contact-page {
    margin: 3% 5%;
    word-wrap: normal;
}

.fml {
    margin: 2.5% 13%;
}

.socials {
    height: 50vh;
    margin: 0% 2%;
}

@media only screen and (max-width: 1032px) {
    h2 {
        font-size: large;
        margin-top: 7%;
        margin-bottom: 5%;
    }

    img {
        width: 50%;
        margin: 2%;
    }

    .booking-email {
        font-size: large;
    }

    .fml {
        float: none;
    }

    .socials {
        font-size: xx-large;
    }
}

@media only screen and (max-width: 835px) {
    .booking-email {
        font-size: medium;
    }
}

@media only screen and (max-width: 400px) {
    .booking-email {
        font-size: small;
    }
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: x-large;
    }

    h3 {
        font-size: xx-large;
    }
}