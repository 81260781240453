main {
    position: relative;
}

p {
    word-wrap: normal;
}

.home-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20vh;
    margin: 3% 3%;
}

.home-child {
    flex-basis: 40%;
    align-self: flex-start;
}

@media only screen and (max-width: 992px) {
    p {
        font-size: xx-large;
    }

    .home-child {
        flex-basis: 75%;
    }
}