div {
    max-width: 100%;
}

.react-player {
    margin: 5% auto 200px auto;
}

.space {
    margin-top: 20%;
    color: black;
}